import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import Contact from './ContactUs';
import { useEffect, useState } from 'react';


function Customers(props) {
  const [mode, setMode] = useState('customers');
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  

  const setModeFunc = async (e) => {
    setMode("")
    let response = await fetch('https://www.hishamshoes.com/app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ "activity": "mode", "mode": e.target.name, "user_id": props.user_id})
      });
      let result = await response.json();
    setMode(e.target.name);

  }
  

  const submitForm = async (activity) => {
    //fetch data
    if (activity == "bill" || activity == "history") {
      let response = await fetch('https://www.hishamshoes.com/app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ "activity": activity, "user_id": props.user_id, phone: phone })
      });
      let result = await response.json();
      if (result.user == "not found") {
        setError(true)
      }
    } else {
      let response = await fetch('https://www.hishamshoes.com/app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ "activity": activity, "user_id": props.user_id, name, email, message })
      });
      let result = await response.json();
      if (result.message == "sent") {
        setError(true)
      }
    }
    
    
    setMode(activity)
  }

  const submit = (e) => {
    setMode("")
    let result = submitForm(e.target.name)
  }

  return (
    <div>
      {mode == 'customers' &&
        <div className='customers'>
          <h1>{props.lang == "ar" ? "أحذية هشام" : "נעלי הישאם"}</h1>
          <Image src="logo.png" style={{ width: "20%", height: "auto", borderRadius: "50%" }} className="my-5" />
          <Button variant="outline-light" style={{ width: "60%", marginTop: "20px" }} name="bill" onClick={setModeFunc}>{props.lang == "ar" ? "عرض الفاتورة" : "צפייה בחשבונית"}</Button>
          <Button variant="outline-light" style={{ width: "60%", marginTop: "20px" }} name="history" onClick={setModeFunc}>{props.lang == "ar" ? "سجل المشتريات السابقة" : "צפייה בהיסטוריית רכישה"}</Button>
          <Button variant="outline-light" style={{ width: "60%", marginTop: "20px" }} name="contact" onClick={setModeFunc}>{props.lang == "ar" ? "الاتصال بالوكيل" : "יצירת קשר עם נציג"}</Button>
        </div>}

      {mode == 'history' &&
        <div className='customers'>

          <h1 className='mb-5'>{props.lang == "ar" ? "سجل المشتريات السابقة" : "היסטוריית רכישה"}</h1>
          <Form style={{ width: "80%" }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.lang == "ar" ? "الرجاء ادخال رقم الهاتف المرتبط بحسابك:" : "אנא הכניסו את מספר הטלפון המקושר לחשבונכם:"}</Form.Label>
              <Form.Control type="phone" name="history" placeholder={props.lang == "ar" ? "رقم الهاتف" : "טלפון"} onChange={(e) => setPhone(e.target.value)} />
            </Form.Group>

            <div className='text-center my-5'><Button onClick={submit} className='mb-3' name="history">{props.lang == "ar" ? "ارسل" : "שלח"}</Button></div>
            {error && <p style={{ color: "red", background: "white" }} className='text-center'>{props.lang == "ar" ? "لا يوجد حساب مرتبط بهذا الرقم" : "לא אותר חשבון המקושר למספר זה"}</p>}
          </Form>
        </div>
      }

      {mode == 'bill' &&
        <div className='customers'>
          <h1 className='mb-5'>{props.lang == "ar" ?  "عرض الفاتورة" : "צפייה בחשבונית"}</h1>
          <Form style={{ width: "80%" }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.lang == "ar" ? "الرجاء ادخال رقم الهاتف المرتبط بحسابك:" : "אנא הכניסו את מספר הטלפון המקושר לחשבונכם:"}</Form.Label>
              <Form.Control type="phone" name="bill" placeholder={props.lang == "ar" ? "رقم الهاتف" : "טלפון"} onChange={(e) => setPhone(e.target.value)} />
            </Form.Group>
            <div className='text-center my-5'><Button onClick={submit} className='mb-3' name="bill">{props.lang == "ar" ? "ارسل" : "שלח"}</Button></div>
            {error && <p style={{ color: "red", background: "white" }} className='text-center'>{props.lang == "ar" ? "لا يوجد حساب مرتبط بهذا الرقم" : "לא אותר חשבון המקושר למספר זה"}</p>}
          </Form>
        </div>
      }

      {mode == "contact" &&
        <div className='customers'>
          <h1>{props.lang == "ar" ? "الاتصال بالوكيل" : "יצירת קשר עם נציג"}</h1>
          <Form style={{ width: "90%" }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.lang == "ar" ? "الاسم": "שם"}</Form.Label>
              <Form.Control type="name" placeholder={props.lang == "ar" ? "الاسم": "שם מלא"} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.lang == "ar" ? "البريد الالكتروني": 'דוא"ל'}</Form.Label>
              <Form.Control type="email" placeholder={props.lang == "ar" ? "البريد الالكتروني": 'כתובת דוא"ל'} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>{props.lang == "ar" ? "ملاحظات": 'הערות'}</Form.Label>
              <Form.Control as="textarea" placeholder={props.lang == "ar" ? "ملاحظات": 'הערות'} rows={6} onChange={(e) => setMessage(e.target.value)} />
            </Form.Group>
            <div className='text-center my-5'><Button onClick={submit} className='mb-3' name="contact">{props.lang == "ar" ? "ارسل" : "שלח"}</Button></div>
            {error && <p style={{ color: "green", background: "white" }} className='text-center'>{props.lang == "ar" ? "تم ارسال البيانات" : "הפרטים נשלחו בהצלחה. תודה על הפנייה!"}</p>}
          </Form>


        </div>


      }

      {mode == "" &&
        <div className='customers'>
          <Spinner animation="border" variant="light" />
        </div>

      }
    </div>
  );
}

export default Customers;