import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

function Contact(props) {
    const [error, setError] = useState(false);

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")


    const submitForm = async () => {
        //fetch data
        
          let response = await fetch('https://www.hishamshoes.com/app', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({ "activity": "contact", "user_id": props.user_id, name, email, message })
          });
          let result = await response.json();
          if (result.message == "sent") {
            setError(true)
          }
        
      }    
    const submit = () =>{
        submitForm()
    }

    return (

    <div className='customers'>
        <h1>{props.lang == "ar" ? "اتصلوا معنا": "צרו קשר"}</h1>
        <Form style={{ width: "90%" }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.lang == "ar" ? "الاسم": "שם"}</Form.Label>
              <Form.Control type="name" placeholder={props.lang == "ar" ? "الاسم": "שם מלא"} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.lang == "ar" ? "البريد الالكتروني": 'דוא"ל'}</Form.Label>
              <Form.Control type="email" placeholder={props.lang == "ar" ? "البريد الالكتروني": 'כתובת דוא"ל'} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>{props.lang == "ar" ? "ملاحظات": 'הערות'}</Form.Label>
              <Form.Control as="textarea" placeholder={props.lang == "ar" ? "ملاحظات": 'הערות'} rows={6} onChange={(e) => setMessage(e.target.value)} />
            </Form.Group>
            <div className='text-center my-5'><Button onClick={submit} className='mb-3' name="contact">{props.lang == "ar" ? "ارسل" : "שלח"}</Button></div>
            {error && <p style={{ color: "green", background: "white" }} className='text-center'>{props.lang == "ar" ? "تم ارسال البيانات" : "הפרטים נשלחו בהצלחה. תודה על הפנייה!"}</p>}
          </Form>
            </div>
    );
}

export default Contact;