import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navb(props) {

  const changeLang = (e) =>{
    props.changeLang(e.target.innerText);
  }

  const tog = () =>{
    if(props.lang== "ar"){
      props.changeLang("עברית")
    }else{
      props.changeLang("عربي")
    }
  }

  const changePage= (e) =>{
    console.log(e.target.name);
    props.change(e.target.name)
  }

  return (
    <Navbar  collapseOnSelect bg="dark" data-bs-theme="dark" expand="lg" className='navbar'>
      <Container>
        <Navbar.Brand onClick={changePage} name="home" href="#">{props.lang == "ar" ? "الرئيسية" : "דף הבית"}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          
            
            <Nav.Link name="stores" href="#" onClick={changePage} >{props.lang=="ar" ? "فروع" : "סניפים"}</Nav.Link>
            <Nav.Link name="sales" href="#" onClick={changePage} >{props.lang == "ar" ? "التنزيلات" :"מבצעים"}</Nav.Link>
            <Nav.Link name="customers" href="#" onClick={changePage} >{props.lang == "ar" ? "الزبائن": "לקוחות"}</Nav.Link>
            <Nav.Link name="contact" href="#" onClick={changePage} >{props.lang == "ar" ? "اتصلوا معنا" : "צרו קשר"}</Nav.Link>
            <Nav.Link onClick={tog} href="#"><p style={{backgroundColor:'black', display:'inline-block', padding:"2px"}}>{props.lang == "ar" ? "עברית" : "العربية"}</p></Nav.Link>
            
            {/* <NavDropdown  collapseOnSelect title={props.lang == "ar" ? "اللغة" : "שפה"} id="nav-dropdown">
              <NavDropdown.Item className='text-end' onClick={changeLang}>עברית</NavDropdown.Item>
              <NavDropdown.Item className='text-end' onClick={changeLang}>العربية</NavDropdown.Item>
            </NavDropdown> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navb;