import vid from './shoes.mp4'
import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navb from './Nav';
import { useEffect, useState } from 'react';
import Home from './Home';
import Customers from './Customers';
import Contact from './ContactUs';
import { Spinner } from 'react-bootstrap';
import Stores from './Stores';
import Sale from './Sale';


function App() {

  const [show, setShow] = useState(false);
  const [data, setData] = useState("")
  const [lang, setLang] = useState("ar")
  const [page, setPage] = useState("home")

  const changeLang = (newLang) => {
    newLang == "عربي" ? setLang("ar") : setLang ("he")
  }
  
  const queryParameters = new URLSearchParams(document.location.search);
  
   useEffect(() => {
    const c = queryParameters.get("c");
    console.log(c);
    if (c == 1){
      setPage("customers")
    }
   }, []);

  const makeRandomId= (length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
   }
   return result;
}

  const user_id = makeRandomId(12)
  const getData = async () => {
    //fetch data
    let response = await fetch('https://www.hishamshoes.com/app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({"activity":"sess","user_id":user_id})
    });
    let result = await response.json();
    setShow(result)
  }

  useEffect(()=>{
    getData();  
  },[])
  

  const changePage=(page) =>{
    setPage(page)
  }
  

  return (
    <div>

      <div className="App">
         <Navb changeLang={changeLang} lang={lang} change={changePage}/>
         <video className="video" src={vid} autoPlay loop muted />
         <div className='overlay'></div>
        {show ?
        <div >
        
          {page == "customers" ? <Customers user_id={user_id} lang={lang} change={changePage}/>: ""}
          
          {page == "contact" ? <Contact lang={lang} change={changePage}/> : ""}
          
          {page == "stores" ? <Stores lang={lang} change={changePage}/> : ""}

          {page == "sales" ? <Sale lang={lang} change={changePage}/> : ""}
          
          {page == "home" ? <Home lang={lang} change={changePage}/> : ""}
          


        </div >
        : <div className="customers"><Spinner animation="border" variant="light" /></div>}
      </div> 

      </div>


  );
}

export default App;
